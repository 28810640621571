.overlay{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10;
    background-color: var(--overlay-background);
}
.invite-heading{
    font-weight: 300;
    text-align: center;
}
.invite-desc{
    font-weight: 100;
    font-size: 1.2em;
    display: grid;
    place-items: center;
    gap: 0.5em;
}
.roomname{
    color:var(--username-color);
    font-weight: 100;
}
.inviteBox{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: var(--text-color);
    background-color: var(--secondary-color);
    box-shadow: 0 0 4px 4px #00c9ff;
    min-height: 45vh;
    padding: 2em;
}
.invite-link{
    display: grid;
    gap: 1em;
    grid-template-columns: 3fr 1fr;
}
.link-box{
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    padding: 0.5em;
}
.closing-button{
    position: absolute;
    border: none;
    background-color: var(--secondary-color);
    color: var(--text-color);
    font-size: 1.3em;
    font-weight: 900;
    left: 94%;
    top: 4%;
}