.left-pane {
  background-color: #232526;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  color: var(--text-color);
  height: 100vh;
  overflow: auto;
}

.user-list-users {
  display: flex !important;
  flex-direction: column;
  gap: 1em;
  width: 100%;
}

.user-list-users li {
  display: grid;
  grid-template-columns: 50px 1fr;
  justify-items: center;
  align-items: center;
  color: var(--username-color);
  font-weight: 700;
  column-gap: 10px;
  overflow: hidden;
  position: relative;
}

.user-image {
  height: 50px;
  width: 50px;
}

.exit-group,
.create-invite {
  width: 100%;
  margin-top: auto;
}

.user-online {
  background-color: var(--online-color);
  border-radius: 50%;
  height: 18px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 18px;
}

.user-list-heading {
  text-align: center;
}
