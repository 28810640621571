.btn {
  width: 100%;
  font-size: 1rem;
  padding: 0.5em 1em;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 1px 1px 1px rgba(83, 83, 83, 0.8);
  transition: background-position 200ms ease-in, box-shadow 100ms ease-in, transform 100ms ease-in;
}

.btn-green {
  color: black;
  background: linear-gradient(to right, #00c9ff, #92fe9d);
  background-size: 200% 200%;
}

.btn-blue {
  background: linear-gradient(to right, #24c6dc, #514a9d);
  box-shadow: 1px 1px 1px 0px rgba(134, 134, 134, 0.8);
  background-size: 200% 200%;
  color: beige;
}

.btn-red {
  background: var(--gradient-danger);
  background-size: 200% 200%;
  color: white;
}

.btn:hover {
  background-position: 50% 50%;
}

.btn:active {
  box-shadow: none;
  transform: scale(0.99);
}

.btn:focus-visible {
  box-shadow: 0 0 5px 1px rgba(0, 132, 255, 0.7);
}
