.root {
  background: var(--background-gradient);
  gap: 1em;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 0.5fr;
  grid-template-areas:
    'title title'
    'info create';
  color: var(--text-color);
}

.root input {
  width: 400px;
  text-align: center;
  font-size: 1.5em;
}

.create {
  grid-area: create;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: flex-start;
  padding-right: 10px;
}

.create button {
  width: 400px;
}

.info {
  display: flex;
  flex-direction: column;
  grid-area: info;
  align-items: center;
  gap: 1em;
  font-size: clamp(20px, 1.5vw, 30px);
}

.info ul {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
}

.title {
  grid-area: title;
  justify-self: center;
  align-self: center;
}

@media screen and (max-width: 1100px) {
  .root {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.25fr 0.5fr;
    grid-template-areas:
      'title'
      'info'
      'create';
  }
  .create {
    align-items: center;
  }
}

.error-message {
  text-align: center;
  background: var(--danger-gradient);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  font-weight: bold;
}
.error-message.show {
  opacity: 1;
}

.create p {
  width: 400px;
  text-align: center;
}
