.card {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  width: 350px;
  text-align: center;
}

.card > img {
  height: 200px;
}
