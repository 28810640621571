@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Open+Sans&display=swap);
#Group_root__5XG2L {
  height: 100vh;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: -webkit-max-content 1fr;
  grid-template-rows: max-content 1fr;
  overflow: hidden;
  background-color: var(--secondary-color);
  grid-template-areas:
    'navbar navbar'
    'left-pane chat-window';
}

#Group_navbar__1v69S {
  background: #151a1f;
  position: relative;
  z-index: 2;
  padding: 1em;
  grid-area: navbar;
}

.ChatWindow_chat-window__QvYlw {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  padding-bottom: 1em;
  min-height: 100%;
  width: 100%;
  background: linear-gradient(to bottom left, var(--primary-color), var(--secondary-color));
  grid-area: chat-window;
  border-radius: 10px;
}
.ChatWindow_roomTitle__1LECM {
  color: var(--text-color);
}
.ChatWindow_participant__2OOCQ {
  padding-top: 0.3rem;
  color: var(--text-color);
  font-weight: 200;
}

.ChatWindow_roomHeading__3UvD- {
  background-color: var(--secondary-color);
  width: 100%;
  padding: 1em;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  flex-direction: column;
  padding-top: 10px;
  justify-content: space-evenly;
}

.ChatWindow_landing-page-layout__2CPXI {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 92vh;
}
#ChatWindow_navbar__2qKG2 {
  background: #151a1f;
  position: relative;
  z-index: 2;
  padding: 0.6em;
  height: 8vh;
  grid-area: navbar;
}

.Input_input-box__3j1Qs {
  height: 50px;
  font-size: 1.3rem;
  width: calc(100% - 50px);
  border-radius: 5px;
  padding: 1em;
  outline: none;
  background-color: #262729;
  transition: box-shadow 200ms ease;
  color: white;
  border: none;
}

.Input_input-box__3j1Qs:focus {
  box-shadow: 0 0 5px 1px rgba(0, 132, 255, 0.7);
}

.Input_input__3PNbO {
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  gap: 20px;
  padding: 0em 2em;
}

.Input_input-send-message__37fof {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
}

.Input_input-send-message__37fof img {
  transition: height 100ms ease-in;
  height: 50px;
}

.Input_input-send-message__37fof img:hover {
  height: 60px;
}

.Messages_chat-messages__2Rj0J {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 1em; */
  word-break: break-all;
}

.Messages_chat-messages-container__1t7eF {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column-reverse;
  scrollbar-color: var(--username-color) var(--secondary-color);
  scrollbar-width: auto;
  position: relative;
  z-index: 2;
}

.Messages_chat-messages-container__1t7eF::-webkit-scrollbar {
  background-color: var(--secondary-color);
  width: 8px;
}

.Messages_chat-messages-container__1t7eF::-webkit-scrollbar-thumb {
  background-color: var(--username-color);
  border-radius: 10px;
}

.Messages_fetch_div__1r3DQ {
  display: flex;
  height: 1px; /*for chrome*/

  justify-content: center;
}

.Message_message__2p-3Q {
  padding: 1em;
  padding-bottom: 0.5em;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 10px;
  width: 95%;
  grid-area: message;
}

.Message_message-user-image__1hev2 {
  height: 50px;
  width: 50px;
  /* grid-area: image; */
}

.Message_message-body__3ljLJ {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--text-color);
}

.Message_message-user-name__TBf-M {
  font-weight: 700;
  color: var(--username-color);
}

.Message_message-body-text__2lPbi {
  /* grid-area: message; */
  color: var(--text-color);
  padding-left: 79px;
  height: -webkit-min-content;
  height: min-content;
  margin-bottom: 0.5em;
}

.Container_flex-container__34QtG {
  display: flex;
}

.Container_grid-container__u1n-N {
  display: grid;
}

.Container_container__3Ff1R {
  min-height: 100vh;
  width: 100vw;
}

div.Loading_loading-container__2gFsh {
  min-height: auto;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.SpinningCircle_spinning-circle__2P9hY {
  width: 50px;
  height: 50px;
  border-bottom: 2px solid var(--green-color);
  border-radius: 50%;
  animation: SpinningCircle_spinning__2BWZq 1000ms ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes SpinningCircle_spinning__2BWZq {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.TypingIndicator_root__3RmH_ {
  color: #acbabf;
  padding: 0 3em;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  justify-content: center;
}

.Error_root__357H7 {
  background-image: var(--background-gradient);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-gap: 1em;
  gap: 1em;
}

.Error_root__357H7 h1 {
  background: var(--gradient-danger);
  background-size: 200%;
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: max(60px, 8vw);
}

.Error_root__357H7 p {
  color: var(--text-color);
  font-size: max(20px, 1.5vw);
}

.ShowInvite_root__1-G-t {
  display: flex;
  height: 100vh;
  justify-content: center;
  background-image: linear-gradient(to bottom right, var(--primary-color) 0%, var(--secondary-color) 100%);
  align-items: center;
  padding: 5px;
}

.ShowInvite_about__zZMa9 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ShowInvite_room-name__2IoVq {
  color: var(--text-color);
  text-align: right;
  font-weight: bold;
  text-align: center;
  font-size: clamp(20px, 2vw, 30px);
}

.ShowInvite_right__4Hngx {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.ShowInvite_btn-pair__1ZlMQ {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
}

.ShowInvite_separator__fA9rb {
  grid-area: separator;
  background: var(--gradient-to-right);
  width: 3px;
  border-radius: 5px;
}

.ShowInvite_container__28Lzt {
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  grid-gap: 10px;
  gap: 10px;
}

.ShowInvite_lucky-text__ptdOo {
  color: var(--text-color);
  font-size: 2.5rem;
}

@media (max-width: 920px) {
  .ShowInvite_heading-text__3-wr1 {
    text-align: right !important;
  }
}

@media (max-width: 800px) {
  .ShowInvite_container__28Lzt {
    flex-direction: column;
    width: auto;
    grid-gap: 2em;
    gap: 2em;
  }
  .ShowInvite_heading-text__3-wr1 {
    text-align: center !important;
  }
  .ShowInvite_about__zZMa9 {
    align-items: center;
  }
}

@media (max-width: 500px) {
  .ShowInvite_btn-pair__1ZlMQ {
    flex-direction: column;
    width: 80%;
  }
}

.Heading_heading__1n3fz {
  background: var(--gradient-to-right);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: clamp(70px, 6vw, 130px);
  text-align: center;
  text-transform: uppercase;
}

.Button_btn__3Ve_g {
  width: 100%;
  font-size: 1rem;
  padding: 0.5em 1em;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 1px 1px 1px rgba(83, 83, 83, 0.8);
  transition: background-position 200ms ease-in, box-shadow 100ms ease-in, transform 100ms ease-in;
}

.Button_btn-green__1XzEJ {
  color: black;
  background: linear-gradient(to right, #00c9ff, #92fe9d);
  background-size: 200% 200%;
}

.Button_btn-blue__1WHWY {
  background: linear-gradient(to right, #24c6dc, #514a9d);
  box-shadow: 1px 1px 1px 0px rgba(134, 134, 134, 0.8);
  background-size: 200% 200%;
  color: beige;
}

.Button_btn-red__1jwxm {
  background: var(--gradient-danger);
  background-size: 200% 200%;
  color: white;
}

.Button_btn__3Ve_g:hover {
  background-position: 50% 50%;
}

.Button_btn__3Ve_g:active {
  box-shadow: none;
  transform: scale(0.99);
}

.Button_btn__3Ve_g:focus-visible {
  box-shadow: 0 0 5px 1px rgba(0, 132, 255, 0.7);
}

.DialogBox_overlay__2RtrN{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10;
    background-color: var(--overlay-background);
}
.DialogBox_invite-heading__36DAU{
    font-weight: 300;
    text-align: center;
}
.DialogBox_invite-desc__1X23w{
    font-weight: 100;
    font-size: 1.2em;
    display: grid;
    place-items: center;
    grid-gap: 0.5em;
    gap: 0.5em;
}
.DialogBox_roomname__2gVk4{
    color:var(--username-color);
    font-weight: 100;
}
.DialogBox_inviteBox__2_CqX{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: var(--text-color);
    background-color: var(--secondary-color);
    box-shadow: 0 0 4px 4px #00c9ff;
    min-height: 45vh;
    padding: 2em;
}
.DialogBox_invite-link__3iwao{
    display: grid;
    grid-gap: 1em;
    gap: 1em;
    grid-template-columns: 3fr 1fr;
}
.DialogBox_link-box__DG2vg{
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    padding: 0.5em;
}
.DialogBox_closing-button__24Df-{
    position: absolute;
    border: none;
    background-color: var(--secondary-color);
    color: var(--text-color);
    font-size: 1.3em;
    font-weight: 900;
    left: 94%;
    top: 4%;
}
.Navbar_navbar__1EhmI {
  display: flex;
  list-style: none;
  justify-content: flex-start;
  grid-gap: 3em;
  gap: 3em;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.5rem;

  color: var(--text-color);
  align-items: center;
}

.Navbar_navbar__1EhmI > a {
  transition: color 250ms ease-in-out;
  text-decoration: none;
  color: var(--text-color);
}

.Navbar_navbar__1EhmI > a:visited {
  color: var(--text-color);
}

.Navbar_navbar__1EhmI > a:hover {
  cursor: pointer;
  color: #06d6a0;
}

#Navbar_app-name__2SOhr {
  text-transform: uppercase;
  font-weight: bold;
  background: var(--gradient-to-right);
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
}

.Rooms_left-pane__2ZzvK {
  background-color: #232526;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  color: var(--text-color);
  height: 100%;
  overflow: auto;
  min-width: 20%;
  grid-gap: 1em;
  gap: 1em;
  grid-area: left-pane;
}

.Rooms_user-list-users__1fd9o {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
}

.Rooms_user-list-users__1fd9o li {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: var(--username-color);
  font-weight: 700;
  font-size: 1.1em;
  overflow: hidden;
  position: relative;
  padding: 0.6em;
}

.Rooms_room-image__2L1B- {
  height: 50px;
  width: 50px;
}
.Rooms_room-info__2pbcn {
  display: flex;
  align-items: center;
  justify-content: left;
}
.Rooms_room-name__1fj64 {
  padding-left: 0.6em;
}
.Rooms_exit-group__UOlOQ,
.Rooms_create-invite__294fH {
  width: 100%;
  margin-top: auto;
}

.LandingPage_landing-window__QHBxG {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  padding-bottom: 2em;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom left, var(--primary-color), var(--secondary-color));
  border-radius: 10px;
  grid-area: chat-window;
}
.LandingPage_description__Mc-Qj {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2.1em;
  color: var(--green-color);
  font-weight: 500;
  font-size: 1.4em;
  height: 100%;
}
.LandingPage_heading__12Usp {
  background-color: var(--secondary-color);
  width: 100%;
  padding: 1.2rem;
  display: flex;
  color: var(--text-color);
  justify-content: space-evenly;
}
.LandingPage_description__Mc-Qj p {
  color: var(--green-color);
  font-size: 1.5em;
}

.Card_card__1pqOg {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  width: 350px;
  text-align: center;
}

.Card_card__1pqOg > img {
  height: 200px;
}

.LeftPane_left-pane__1Uo03 {
  background-color: #232526;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  color: var(--text-color);
  height: 100vh;
  overflow: auto;
}

.LeftPane_user-list-users__1q53n {
  display: flex !important;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
}

.LeftPane_user-list-users__1q53n li {
  display: grid;
  grid-template-columns: 50px 1fr;
  justify-items: center;
  align-items: center;
  color: var(--username-color);
  font-weight: 700;
  grid-column-gap: 10px;
  column-gap: 10px;
  overflow: hidden;
  position: relative;
}

.LeftPane_user-image__2Coyj {
  height: 50px;
  width: 50px;
}

.LeftPane_exit-group__1z_FP,
.LeftPane_create-invite__12tis {
  width: 100%;
  margin-top: auto;
}

.LeftPane_user-online__CAwPg {
  background-color: var(--online-color);
  border-radius: 50%;
  height: 18px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 18px;
}

.LeftPane_user-list-heading__14K_Q {
  text-align: center;
}

.Login_root__3dgz_ {
  display: grid;
  height: 100vh;
  grid-template-columns: 0.6fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    '. .'
    'about form'
    '. .';
  align-items: center;
  background-image: linear-gradient(
    to bottom right,
    var(--primary-color) 0%,
    var(--secondary-color) 100%
  );
  justify-items: center;
}

@media screen and (max-width: 1250px) {
  .Login_root__3dgz_ {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.LoginForm_form__3mT71 {
  display: flex;
  flex-direction: column;
  width: 50%;
  grid-gap: 1em;
  gap: 1em;
  grid-area: form;
  padding-top: 4em;
  max-width: 600px;
}

.LoginForm_pair__3GT6S {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  row-gap: 10px;
  font-size: clamp(15px, 3vw, 30px);
  width: 100%;
}

label {
  color: var(--text-color);
  text-align: left;
}

.LoginForm_register-text__3GDjh {
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  text-align: center;
}

.InputField_input__2QPh5 {
  font-size: clamp(12px, 2vw, 40px);
  padding: 10px;
  color: var(--text-color);
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
}

.InputField_input__2QPh5:focus {
  box-shadow: 0 0 5px 1px var(--primary-color);
}

.ButtonFormPopup_error-message__2_UF3 {
  text-align: center;
  background: var(--danger-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  font-weight: bold;
}
.ButtonFormPopup_error-message__2_UF3.ButtonFormPopup_show__1Kque {
  opacity: 1;
}

.ButtonFormPopup_bottom__1DiQd {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  transform: translateY(-25px);
  transition: transform 250ms ease-in;
}

.ButtonFormPopup_bottom__1DiQd.ButtonFormPopup_show__1Kque {
  transform: translateX(0);
}

.PasswordField_span-eye-btn__lazFV {
  position: absolute;
  right: 5px;
  height: clamp(25px, 80%, 50px);

  /* center it vertically */
  top: 50%;
  transform: translateY(-50%);
}

.About_about__3fXYV {
  grid-area: about;
  justify-self: flex-end;
}

.SignUpForm_form__1dO4V {
  display: flex;
  flex-direction: column;
  width: 50%;
  grid-gap: 1em;
  gap: 1em;
  grid-area: form;
  padding-top: 4em;
  max-width: 600px;
  position: relative;
}

.SignUpForm_pair__19Vn2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  row-gap: 10px;
  font-size: clamp(15px, 3vw, 30px);
  width: 100%;
}

.SignUpForm_error-message__2AnBn {
  text-align: center;
  background: var(--danger-gradient);
  background-clip: text;
  color: transparent;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.SignUpForm_error-message__2AnBn.SignUpForm_show__2qRDo {
  opacity: 1;
}

label {
  color: var(--text-color);
  text-align: left;
}

.SignUpForm_register-text__3U5uw {
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  text-align: center;
}

.SignUpForm_bottom__13o-r {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  transform: translateY(-25px);
  transition: transform 250ms ease-in;
}

.SignUpForm_bottom__13o-r.SignUpForm_show__2qRDo {
  transform: translateX(0);
}

.CreateRoom_root__b1LAz {
  background: var(--background-gradient);
  grid-gap: 1em;
  gap: 1em;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 0.5fr;
  grid-template-areas:
    'title title'
    'info create';
  color: var(--text-color);
}

.CreateRoom_root__b1LAz input {
  width: 400px;
  text-align: center;
  font-size: 1.5em;
}

.CreateRoom_create__2R-wu {
  grid-area: create;
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  align-items: flex-start;
  padding-right: 10px;
}

.CreateRoom_create__2R-wu button {
  width: 400px;
}

.CreateRoom_info__3xmS6 {
  display: flex;
  flex-direction: column;
  grid-area: info;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  font-size: clamp(20px, 1.5vw, 30px);
}

.CreateRoom_info__3xmS6 ul {
  display: flex;
  flex-direction: column;
  grid-gap: 0.3em;
  gap: 0.3em;
}

.CreateRoom_title__ai7d_ {
  grid-area: title;
  justify-self: center;
  align-self: center;
}

@media screen and (max-width: 1100px) {
  .CreateRoom_root__b1LAz {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.25fr 0.5fr;
    grid-template-areas:
      'title'
      'info'
      'create';
  }
  .CreateRoom_create__2R-wu {
    align-items: center;
  }
}

.CreateRoom_error-message__2cxLN {
  text-align: center;
  background: var(--danger-gradient);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  font-weight: bold;
}
.CreateRoom_error-message__2cxLN.CreateRoom_show__3NLOF {
  opacity: 1;
}

.CreateRoom_create__2R-wu p {
  width: 400px;
  text-align: center;
}

.JoinRoom_root__kK2jw {
  background: var(--background-gradient);
  color: var(--text-color);
  padding-top: 1em;
  grid-template-rows: 1fr 1fr;
  align-items: center;

  padding: 10px;
}

.JoinRoom_roomId__18xT8 {
  text-align: center;
  width: 300px;
  font-size: 40px;
  transition: outline 100ms ease;
}

.JoinRoom_roomId__18xT8.JoinRoom_invalid__1RD6p {
  outline: 2px solid red;
}

.JoinRoom_title__2jXh_ {
  font-size: clamp(100px, 6vw, 130px);
}

.JoinRoom_pair__eJOL8 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  align-self: flex-start;
}

.JoinRoom_pair__eJOL8 p {
  width: 300px;
  text-align: center;
  align-items: center;
}

.JoinRoom_root__kK2jw button {
  width: 300px;
  align-self: center;
}

.Home_root__2XTgM {
  display: grid;
  grid-template-rows: 60px auto 1fr;
  grid-template-areas:
    'navbar'
    'banner'
    'technologies';
  min-height: 100vh;
}

.Home_top__1N9Lw {
  display: grid;
  min-height: 50vh;
  width: 100vw;
  grid-template-columns: 2fr 1fr;
  background: #151a1f;
  grid-template-areas: 'app image';
  grid-area: banner;
}

#Home_app-name__CZP_c {
  font-size: clamp(40px, 4vw, 100px);
  background: linear-gradient(to bottom right, #1fa2ff, #12d8fa 70%, #a6ffcb 100%);
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
}

.Home_btn__ZsE6H {
  text-decoration: none;
  color: black;
  width: -webkit-max-content;
  width: max-content;
  padding: 1em 2em;
  border-radius: 5px;
  box-shadow: rgb(112, 112, 112) 0px 1px 5px;
}

#Home_get-started-btn__3CIJ0 {
  background: var(--green-color);
}

a.Home_btn__ZsE6H:visited {
  color: black;
}

#Home_github-btn__2D7fT,
a[id='github-btn'] {
  color: var(--text-color);
}

#Home_get-started-btn__3CIJ0:hover {
  background-color: #29ffc6d0;
}

.Home_btn__ZsE6H:active {
  transform: scale(0.95);
}

#Home_app-footer__2UGyP {
  color: var(--text-color);
}

#Home_navbar__gClNA {
  background: #151a1f;
  position: relative;
  z-index: 2;
  padding: 1em;
  grid-area: navbar;
}

#Home_app-about__ybqmx {
  color: var(--text-color);
  font-size: clamp(20px, 1.5vw, 30px);
  max-width: 50ch;
}

#Home_technology__1ncLK {
  grid-area: technologies;
  padding-top: 1em;
  padding-bottom: 1em;
}

.Home_app__1VLG4 {
  grid-area: app;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  padding: 3em;
  padding-bottom: 1.5em;
  height: -webkit-max-content;
  height: max-content;
}

#Home_react-spinning-image__1rgjq {
  animation: Home_spinning__2ubAO 2500ms linear;
  animation-iteration-count: infinite;
  grid-area: image;
  justify-self: center;
  align-self: center;
  height: clamp(256px, min(45vh, 45vw), 500px);
}

.Home_btn-bar__1VZkM {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
}
.Home_landing-page-layout__vtrSo {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: -webkit-max-content 1fr;
  grid-template-rows: max-content 1fr;
  /* overflow: hidden; */
  /* background-color: var(--secondary-color); */
  grid-template-areas:
    'navbar navbar'
    'left-pane chat-window';
  min-height: 100vh;
}

@keyframes Home_spinning__2ubAO {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 778px) {
  .Home_top__1N9Lw {
    grid-template-columns: 1fr;
    grid-template-rows: -webkit-max-content -webkit-max-content;
    grid-template-rows: max-content max-content;
    grid-template-areas:
      'image'
      'app';
    height: -webkit-max-content;
    height: max-content;
    justify-content: center;
    text-align: center;
    align-content: center;
  }

  .Home_app__1VLG4 {
    padding-top: 0;
    justify-content: start;
  }

  .Home_btn__ZsE6H {
    width: 100%;
  }

  #Home_navbar__gClNA > a {
    margin-left: auto;
    margin-right: auto;
  }

  /* a:first-child {
    width: 100vw;
    text-align: center;
  } */
  #Home_navbar__gClNA {
    grid-gap: 0;
    gap: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .Home_btn-bar__1VZkM {
    flex-direction: column;
  }
}

.Technologies_root__IJQ5x {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  height: 100%;
}

.Technologies_heading__1f2BX {
  width: 100%;
  text-align: center;
  font-size: clamp(40px, 2vw, 60px);
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  --primary-color: #414345;
  --secondary-color: #232526;
  --text-color: #edf6f9;
  --username-color: #4cc9f0;
  --gradient-to-right: linear-gradient(to right, #29ffc6, #20e3b2, #0cebeb);
  --gradient-to-bottom-left: linear-gradient(to bottom right, #29ffc6, #20e3b2, #0cebeb);
  --green-color: #29ffc6;
  --gradient-danger: linear-gradient(to right, #da4453, #89216b);
  --danger-gradient: linear-gradient(to left, #ee0979, #ff6a00);
  --background-gradient: linear-gradient(
    to bottom right,
    var(--primary-color) 0%,
    var(--secondary-color) 100%
  );
  --online-color: #3cd64e;
  --overlay-background: rgba(0, 0, 0, 0.45);
}

body,
html {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
}

a:visited,
a {
  color: var(--username-color);
  text-decoration: none;
}

#root {
  background: var(--background-gradient);
}
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 20px;
  top: 20px;
}
.bm-burger-bars {
  background: var(--text-color);
}

.bm-menu {
  width: 100%;
  top: 0;
}

.bm-menu-wrap {
  width: 100%;
  top: 0;
}

.bm-overlay {
  top: 0;
}

.bm-item-list {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  flex-direction: column;
}

