#root {
  height: 100vh;
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: max-content 1fr;
  overflow: hidden;
  background-color: var(--secondary-color);
  grid-template-areas:
    'navbar navbar'
    'left-pane chat-window';
}

#navbar {
  background: #151a1f;
  position: relative;
  z-index: 2;
  padding: 1em;
  grid-area: navbar;
}
