.root {
  display: flex;
  height: 100vh;
  justify-content: center;
  background-image: linear-gradient(to bottom right, var(--primary-color) 0%, var(--secondary-color) 100%);
  align-items: center;
  padding: 5px;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.room-name {
  color: var(--text-color);
  text-align: right;
  font-weight: bold;
  text-align: center;
  font-size: clamp(20px, 2vw, 30px);
}

.right {
  display: flex;
  gap: 1em;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.btn-pair {
  display: flex;
  gap: 1em;
  width: 100%;
}

.separator {
  grid-area: separator;
  background: var(--gradient-to-right);
  width: 3px;
  border-radius: 5px;
}

.container {
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  gap: 10px;
}

.lucky-text {
  color: var(--text-color);
  font-size: 2.5rem;
}

@media (max-width: 920px) {
  .heading-text {
    text-align: right !important;
  }
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
    width: auto;
    gap: 2em;
  }
  .heading-text {
    text-align: center !important;
  }
  .about {
    align-items: center;
  }
}

@media (max-width: 500px) {
  .btn-pair {
    flex-direction: column;
    width: 80%;
  }
}
