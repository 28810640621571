.chat-window {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-bottom: 1em;
  min-height: 100%;
  width: 100%;
  background: linear-gradient(to bottom left, var(--primary-color), var(--secondary-color));
  grid-area: chat-window;
  border-radius: 10px;
}
.roomTitle {
  color: var(--text-color);
}
.participant {
  padding-top: 0.3rem;
  color: var(--text-color);
  font-weight: 200;
}

.roomHeading {
  background-color: var(--secondary-color);
  width: 100%;
  padding: 1em;
  display: flex;
  gap: 1em;
  flex-direction: column;
  padding-top: 10px;
  justify-content: space-evenly;
}

.landing-page-layout {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 92vh;
}
#navbar {
  background: #151a1f;
  position: relative;
  z-index: 2;
  padding: 0.6em;
  height: 8vh;
  grid-area: navbar;
}
