.chat-messages {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 1em; */
  word-break: break-all;
}

.chat-messages-container {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column-reverse;
  scrollbar-color: var(--username-color) var(--secondary-color);
  scrollbar-width: auto;
  position: relative;
  z-index: 2;
}

.chat-messages-container::-webkit-scrollbar {
  background-color: var(--secondary-color);
  width: 8px;
}

.chat-messages-container::-webkit-scrollbar-thumb {
  background-color: var(--username-color);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.fetch_div {
  display: flex;
  height: 1px; /*for chrome*/

  justify-content: center;
}
