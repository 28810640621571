.input {
  font-size: clamp(12px, 2vw, 40px);
  padding: 10px;
  color: var(--text-color);
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
}

.input:focus {
  box-shadow: 0 0 5px 1px var(--primary-color);
}
