.left-pane {
  background-color: #232526;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  color: var(--text-color);
  height: 100%;
  overflow: auto;
  min-width: 20%;
  gap: 1em;
  grid-area: left-pane;
}

.user-list-users {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
}

.user-list-users li {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: var(--username-color);
  font-weight: 700;
  font-size: 1.1em;
  overflow: hidden;
  position: relative;
  padding: 0.6em;
}

.room-image {
  height: 50px;
  width: 50px;
}
.room-info {
  display: flex;
  align-items: center;
  justify-content: left;
}
.room-name {
  padding-left: 0.6em;
}
.exit-group,
.create-invite {
  width: 100%;
  margin-top: auto;
}
