.landing-window {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-bottom: 2em;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom left, var(--primary-color), var(--secondary-color));
  border-radius: 10px;
  grid-area: chat-window;
}
.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2.1em;
  color: var(--green-color);
  font-weight: 500;
  font-size: 1.4em;
  height: 100%;
}
.heading {
  background-color: var(--secondary-color);
  width: 100%;
  padding: 1.2rem;
  display: flex;
  color: var(--text-color);
  justify-content: space-evenly;
}
.description p {
  color: var(--green-color);
  font-size: 1.5em;
}
