.input-box {
  height: 50px;
  font-size: 1.3rem;
  width: calc(100% - 50px);
  border-radius: 5px;
  padding: 1em;
  outline: none;
  background-color: #262729;
  transition: box-shadow 200ms ease;
  color: white;
  border: none;
}

.input-box:focus {
  box-shadow: 0 0 5px 1px rgba(0, 132, 255, 0.7);
}

.input {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0em 2em;
}

.input-send-message {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
}

.input-send-message img {
  transition: height 100ms ease-in;
  height: 50px;
}

.input-send-message img:hover {
  height: 60px;
}
