@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Open+Sans&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
  --primary-color: #414345;
  --secondary-color: #232526;
  --text-color: #edf6f9;
  --username-color: #4cc9f0;
  --gradient-to-right: linear-gradient(to right, #29ffc6, #20e3b2, #0cebeb);
  --gradient-to-bottom-left: linear-gradient(to bottom right, #29ffc6, #20e3b2, #0cebeb);
  --green-color: #29ffc6;
  --gradient-danger: linear-gradient(to right, #da4453, #89216b);
  --danger-gradient: linear-gradient(to left, #ee0979, #ff6a00);
  --background-gradient: linear-gradient(
    to bottom right,
    var(--primary-color) 0%,
    var(--secondary-color) 100%
  );
  --online-color: #3cd64e;
  --overlay-background: rgba(0, 0, 0, 0.45);
}

body,
html {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
}

a:visited,
a {
  color: var(--username-color);
  text-decoration: none;
}

#root {
  background: var(--background-gradient);
}
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 20px;
  top: 20px;
}
.bm-burger-bars {
  background: var(--text-color);
}

.bm-menu {
  width: 100%;
  top: 0;
}

.bm-menu-wrap {
  width: 100%;
  top: 0;
}

.bm-overlay {
  top: 0;
}

.bm-item-list {
  display: flex;
  gap: 1em;
  flex-direction: column;
}
